import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faEllipsisH,
	/* faEye, */
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
	Button,
	Dropdown,
	ButtonGroup,
	Badge,
	Col,
	Row,
} from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Tables/Table';
import { getLoads, multipleEliminationsLoads } from '../../services/api/loads';
import DeleteModel from '../../components/Modal/DeleteModel';
import levelAccessConstants from '../../utils/constants/levelAccess.constants';

export default ({ levelAccess }) => {
	const [list, setList] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [loading, setLoading] = useState(true);
	const [filterValues, setFilterValues] = useState(null);
	const [timer, setTimer] = useState(null);
	const [lastFieldSearch, setLastFieldSearch] = useState(null);
	const [totalRowSelection, setTotalRowSelection] = useState(0);
	const [showDeleteModel, setShowDeleteModel] = useState(false);
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);
	const [rowSelectionByPage, setRowSelectionByPage] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const history = useHistory();

	const columns = [
		{
			header: '#',
			footer: (props) => props.column.id,
			accessorKey: 'item.load_id',
			cell: (info) => info.getValue(),
			sort: true,
		},
		{
			header: 'Origin',
			footer: (props) => props.column.id,
			accessorKey: 'origin_city_and_state',
			cell: (info) => info.getValue(),
		},
		{
			header: 'Destination',
			footer: (props) => props.column.id,
			accessorKey: 'destination_city_and_state',
			cell: (info) => info.getValue(),
		},
		{
			header: 'Loading condition',
			footer: (props) => props.column.id,
			accessorKey: 'item.equipment_details',
			cell: (info) =>
				`${info.getValue()[0].toLocaleUpperCase()}${info.getValue().substr(1)}`,
			filter: 'select',
			filterValues: [
				{
					id: 'Full',
					text: 'Full',
				},
				{
					id: 'Partial',
					text: 'Partial',
				},
			],
		},
		{
			header: 'Pickup',
			footer: (props) => props.column.id,
			accessorKey: 'item.load_pickup',
			cell: (info) => info.getValue(),
		},
		{
			header: 'Miles',
			footer: (props) => props.column.id,
			accessorKey: 'item.miles',
			cell: (info) => info.getValue(),
		},
		{
			header: 'Equipment',
			footer: (props) => props.column.id,
			accessorKey: 'item.load_equipment_type.equipment_name',
			cell: (info) => info.getValue(),
		},
		{
			header: 'Status',
			footer: (props) => props.column.id,
			accessorKey: 'item.load_status',
			cell: (info) => {
				const statusVariant =
					info.row.original.item.load_status === 'active' ? 'success' : 'danger';

				return <span className={`text-${statusVariant}`}>{info.getValue()}</span>;
			},
		},
		{
			header: 'Carrier',
			footer: (props) => props.column.id,
			accessorKey: 'item.carrier',
			cell: (info) => {
				const carrier = info.row.original.item.carrier;
				let carrierNames = '';

				if (carrier) {
					carrierNames = `${carrier.user_first_name} ${carrier.user_last_name}`;
				}

				return carrierNames;
			},
		},
		{
			header: 'MC or DOT',
			footer: (props) => props.column.id,
			accessorKey: 'item.carrier.user_detail.carrier_type',
			cell: (info) => {
				const carrier = info.row.original.item?.carrier;
				const userDetail = carrier?.user_detail;

				//Verificar si userDetail y carrier_type_value existen
				if (userDetail?.carrier_type && userDetail?.carrier_type_value) {
					return `${carrier.user_detail.carrier_type}: ${carrier.user_detail.carrier_type_value}`;
				}

				return ' ';
			},
		},
	];

	if (levelAccess && levelAccess?.level_id === levelAccessConstants.BROKER) {
		columns.push({
			header: 'Action',
			cell: (info) => (
				<Dropdown as={ButtonGroup}>
					<Dropdown.Toggle
						as={Button}
						split
						variant="link"
						className="text-dark m-0 p-0">
						<span className="icon icon-sm">
							<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
						</span>
					</Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-menu-option">
						{/* <Dropdown.Item>
              <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
            </Dropdown.Item> */}
						<Dropdown.Item
							onClick={() =>
								history.push(`/loads/${info.row.original.item.load_id}`)
							}>
							<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
						</Dropdown.Item>
						{/* <Dropdown.Item className="text-danger">
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
            </Dropdown.Item> */}
					</Dropdown.Menu>
				</Dropdown>
			),
		});
	}

	const filterColumns = [
		'origin_city_and_state',
		'destination_city_and_state',
		'item_load_equipment_type.equipment_name',
		'item_equipment_details',
	];
	const [initialSorting, setInitialSorting] = useState([
		{
			desc: true,
			id: 'item_load_id',
		},
	]);

	const setDataList = (response) => {
		setList(response.data);
		setPagination({
			pageCount: response.pagination.pageCount,
			pageSize: response.pagination.take,
			pageIndex: response.pagination.page - 1,
			itemCount: response.pagination.itemCount,
		});
	};

	const getPaginationList = useCallback(
		async (numberPage, numberTake, filter, sort) => {
			setLoading(true);
			setCurrentPage(numberPage);
			// Transform structure
			if (filter) {
				filter = {
					equipment_name: filter['item_load_equipment_type.equipment_name'] ?? '',
					destination: filter['destination_city_and_state'] ?? '',
					origin: filter['origin_city_and_state'] ?? '',
					equipment_loading_condition: filter['item_equipment_details'] ?? '',
				};
			}

			const data = await getLoads(numberPage, numberTake, filter, sort);

			setDataList(data);

			setLoading(false);
		},
		[]
	);

	const handleFilter = (filter) => {
		const isFilterText = columns.some(
			(column) =>
				column?.accessorKey?.replace('.', '_') === filter.id &&
				column?.filter !== 'select'
		);

		if (timer && isFilterText) {
			clearTimeout(timer);
		}

		setLastFieldSearch(filter.id);

		const newFilter = {
			...filterValues,
			[filter.id]: filter.value,
		};
		setFilterValues(newFilter);

		if (isFilterText) {
			const newTimer = setTimeout(async () => {
				getPaginationList(1, 10, newFilter);
			}, 700);
			setTimer(newTimer);

			return;
		}

		getPaginationList(1, 10, newFilter);
	};

	const handleSortingChange = (value) => {
		setInitialSorting(value);
		getPaginationList(1, 10, filterValues, value[0]);
	};

	const handleYes = () => {
		setIsLoadingDelete(true);

		const ids = [];
		Object.keys(rowSelectionByPage).forEach((index) => {
			rowSelectionByPage[index].forEach((selection) => {
				ids.push(selection.original.item.load_id);
			});
		});

		multipleEliminationsLoads(ids)
			.then(() => {
				setShowDeleteModel(false);
				setRowSelectionByPage([]);
				setTotalRowSelection(0);
				getPaginationList(1, 10);
			})
			.finally(() => setIsLoadingDelete(false));
	};

	const handleRowSelectionChange = (rowSelection) => {
		let totalRowSelection = 0;

		if (!rowSelection.length) {
			setRowSelectionByPage({
				...rowSelectionByPage,
				[currentPage]: [],
			});

			let existRowSelection = false;

			Object.keys(rowSelectionByPage).forEach((index) => {
				if (
					!existRowSelection &&
					Number(index) !== currentPage &&
					rowSelectionByPage[index]?.length > 0
				) {
					existRowSelection = true;
				}
			});

			if (!existRowSelection) {
				setTotalRowSelection(0);
			} else {
				totalRowSelection = Object.keys(rowSelectionByPage)
					.filter((index) => Number(index) !== currentPage)
					.reduce((total, index) => total + rowSelectionByPage[index].length, 0);

				setTotalRowSelection(totalRowSelection);
			}

			return;
		}

		setRowSelectionByPage({
			...rowSelectionByPage,
			[currentPage]: rowSelection,
		});

		totalRowSelection = Object.keys(rowSelection).length;

		totalRowSelection += Object.keys(rowSelectionByPage)
			.filter((index) => Number(index) !== currentPage)
			.reduce((total, index) => total + rowSelectionByPage[index].length, 0);

		setTotalRowSelection(totalRowSelection);
	};

	useEffect(() => {
		getPaginationList(1, 10);
	}, [getPaginationList]);

	return (
		<>
			{totalRowSelection > 0 && (
				<div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
					<div className="d-block mb-4 mb-xl-0 w-100">
						<Row>
							<Col xs={12} className="mb-4">
								<Row>
									<Col xs={10}></Col>
									<Col xs={2} className="text-right">
										<Button
											variant="danger"
											onClick={() => setShowDeleteModel(true)}>
											<FontAwesomeIcon icon={faTrashAlt} className="me-2" />
											<Badge bg="primary" className="me-2">
												{totalRowSelection}
											</Badge>
											Delete
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</div>
			)}
			{loading ? (
				<h1>Loading...</h1>
			) : (
				<Table
					columns={columns}
					data={list}
					pagination={pagination}
					handlePagination={getPaginationList}
					filterColumns={filterColumns}
					handleFilter={handleFilter}
					filterValues={filterValues}
					lastFieldSearch={lastFieldSearch}
					handleSortingChange={handleSortingChange}
					initialSorting={initialSorting}
					showIndeterminateCheckbox={true}
					handleRowSelectionChange={handleRowSelectionChange}
					rowsSelections={rowSelectionByPage[currentPage]}
					totalRowSelection={totalRowSelection}
				/>
			)}

			<DeleteModel
				show={showDeleteModel}
				handleClose={setShowDeleteModel}
				handleYes={handleYes}
				isLoading={isLoadingDelete}
			/>
		</>
	);
};
